<template>
  <QuestionCard
    :Question="Question"
    :is_selected="is_selected"
    :allows_crud="allows_crud"
    :evaluation_id="evaluation_id"
    :answer_score="answer_score"
    :is_running="is_running"
    :how_many_selected="question.how_many_selected"
    :question_score_error="question_score_error"
    @selected="slotSelectedQuestion"
    @created="slotQuestionCopied"
    @questionCopied="slotQuestionCopied"
    @questionPatched="slotQuestionPatched"
    @deletedQuestion="slotDeletedQuestion"
    @questionScoreUpdated="slotQuestionScoreUpdated"
    :empty_options="
      multiple_choice_question_answer.selected.length == 0 &&
      question.how_many_selected == 1 &&
      user.is_student &&
      save_attempt > 0 &&
      !is_finished
        ? true
        : false
    "
    :options_remaining="
      question.how_many_selected > 1 &&
      multiple_choice_question_answer.selected.length <
        question.how_many_selected &&
      user.is_student &&
      save_attempt > 0 &&
      !is_finished
        ? true
        : false
    "
  >
    <slot>
      <div class="content-div">
        <draggable
          v-model="optionsList"
          :disabled="!allows_crud"
          handle=".handle"
        >
          <transition-group>
            <template>
              <div
                v-for="option in optionsList"
                :key="option.id"
                class="options-div"
                :class="{ 'draggable-section': allows_crud }"
              >
                <div class="option-div d-flex align-middle" :key="option.order">
                  <b-icon
                    v-if="allows_crud"
                    class="handler-icon handle"
                    icon="arrows-expand"
                    :class="{ 'grabbable-cursor': allows_crud }"
                  ></b-icon>
                  <b-form-checkbox
                    v-if="!is_finished"
                    :key="`option-checkbox-${option.id}`"
                    :checked="
                      multiple_choice_question_answer.selected == option.id
                    "
                    v-model="multiple_choice_question_answer.selected"
                    :disabled="
                      user.is_professor ||
                      !is_running ||
                      is_finished ||
                      is_saving > 0 ||
                      (question.how_many_selected > 1 &&
                        multiple_choice_question_answer.selected.length ==
                          question.how_many_selected &&
                        multiple_choice_question_answer.selected.indexOf(
                          option.id
                        ) === -1)
                    "
                    :value="option.id"
                    @change="debouncePatch(option.id)"
                    class="check-question"
                  >
                  </b-form-checkbox>

                  <b-icon
                    v-else-if="
                      question.how_many_selected == 1 &&
                      user.is_student &&
                      is_finished &&
                      multiple_choice_question_answer.selected == option.id
                    "
                    icon="check-square-fill"
                    variant="primary"
                    class="check-icon"
                  ></b-icon>
                  <b-icon
                    v-else-if="
                      question.how_many_selected > 1 &&
                      user.is_student &&
                      is_finished &&
                      multiple_choice_question_answer.selected.includes(
                        option.id
                      )
                    "
                    icon="check-square-fill"
                    variant="primary"
                    class="check-icon"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="square"
                    variant="secondary"
                    class="square-icon"
                  ></b-icon>
                  <OptionRow
                    :key="option_row_key"
                    class="option-text"
                    :class="{ 'margin-bottom-adjust': is_finished }"
                    :question_id="Question.id"
                    :Option="option"
                    :selected_option_type="Question.score_type"
                    :allows_crud="allows_crud"
                    :is_running="is_running"
                    :can_answer="can_answer"
                    :is_finished="is_finished"
                    :how_many_selected="question.how_many_selected"
                    :question_score_error="question_score_error"
                    @delete="slotDeleteTempOption"
                    @deleted="slotDeleteOption"
                    @optionScoreChanged="slotOptionScoreChanged"
                    @optionSingleScoreChanged="slotOptionSingleScoreChanged"
                    @getOptionScore="slotGetOptionScore"
                  ></OptionRow>
                  <!-- @selection="slotAnswerSelected" -->
                </div>
              </div>
            </template>
          </transition-group>
        </draggable>
        <div
          class="text-left mt-1"
          v-if="
            multiple_choice_question_answer &&
            !is_running &&
            multiple_choice_question_answer.score_and_feedback &&
            questionTestScore &&
            questionTestScore.score_and_feedback &&
            questionTestScore.score_and_feedback.publish
          "
        >
          <template
            v-if="
              multiple_choice_question_answer.score_and_feedback.feedback.trim() !=
              ''
            "
          >
            <strong>Retroalimentación:</strong>
            <p class="answer p-1">
              {{ multiple_choice_question_answer.score_and_feedback.feedback }}
            </p>
          </template>
          <template v-if="Question && Question.comment.trim() != ''">
            <strong>Pauta:</strong>
            <p class="answer p-1">
              {{ Question.comment }}
            </p>
          </template>
        </div>
        <div
          v-if="allows_crud && question_score_error"
          class="options_score_error mb-2"
        >
          <b-icon icon="exclamation-circle" style="margin-bottom: 1px"></b-icon>
          <span class="ml-2" v-if="question && question.how_many_selected > 1"
            >El puntaje de las opciones no coincide con el total de la
            pregunta.</span
          >
        </div>
        <div class="w-100">
          <b-button
            class="text-center px-3 my-1"
            size="sm"
            v-if="allows_crud"
            @click="addOption"
            ><b-icon icon="plus"></b-icon> Agregar opción</b-button
          >
        </div>
        <div class="d-flex">
          <div v-if="allows_crud && user.is_professor">
            <b-form-group
              label="Opciones a seleccionar:"
              label-cols="4"
              label-cols-sm="8"
              label-class="how-many-options-label"
              class="mb-0 text-left"
            >
              <b-form-input
                type="number"
                size="sm"
                class="how-many-selected-input"
                v-model.number="question.how_many_selected"
                :max="Question.options ? Question.options.length : null"
                @input="howManySelectedLimit"
                :disabled="how_many_selected_limit"
              ></b-form-input>
            </b-form-group>
            <div v-if="how_many_selected_limit" class="text-alert blinking">
              <b-icon icon="exclamation-circle"></b-icon> No puede superar el
              total de opciones de la pregunta.
            </div>
            <b-form-group
              v-if="question.how_many_selected >= 2"
              label="Método de cálculo de puntaje:"
              label-cols="4"
              label-cols-sm="8"
              class="how-many-options-label text-left"
            >
              <b-form-select
                :id="`automated-score-type-${question.id}`"
                text-field="value"
                value-field="id"
                size="sm"
                v-model="question.automated_score_type"
                :options="automatedScoreTipes"
              >
              </b-form-select>
              <b-tooltip
                :target="`automated-score-type-${question.id}`"
                variant="secondary"
                noninteractive
              >
                <div class="automated-score-type-info">
                  <strong>Suma:</strong> resultado de la suma de todas las
                  opciones seleccionadas.
                  <br />
                  <strong>Promedio:</strong> corresponde a la media del total de
                  opciones seleccionadas.
                  <br />
                  <strong>Máximo:</strong> Se considera válida la opción con el
                  puntaje asignado más alto de la selección.
                  <br />
                  <strong>Mínimo:</strong> Se considera válida la opción con el
                  puntaje asignado más bajo de la selección.
                </div>
              </b-tooltip>
            </b-form-group>
          </div>
          <div v-else class="mb-1 noprint">
            <div v-if="user.is_professor">
              Opciones a seleccionar: {{ question.how_many_selected
              }}<span v-if="question.how_many_selected >= 2">
                - Método de cálculo de puntaje: {{ automatedScoreValue }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </slot>
    <div class="save-btn-container">
      <span
        v-if="saved && !is_finished"
        class="saved-answer-alert text-success mr-2 pt-1"
      >
        <b-icon icon="check-circle"></b-icon> Se ha guardado su respuesta.
      </span>
      <b-button
        class="save-answer-btn"
        :class="{ 'saving-answer-btn': is_saving > 0, 'ml-0': saved }"
        @click="forcePatch(multiple_choice_question_answer.selected[0])"
        v-if="is_running && user.is_student && !is_finished"
        :disabled="!is_running || is_saving > 0"
      >
        <div class="btn-save-container">
          {{ is_saving > 0 ? "Guardando" : "Guardar Respuesta" }}
          <div v-if="is_saving > 0" class="dot-flashing"></div>
        </div>
        <!-- Guardar Respuesta -->
      </b-button>
    </div>
  </QuestionCard>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import draggable from "vuedraggable";

export default {
  name: "SimgleSelectQuestion",
  components: {
    draggable,
    QuestionCard: () => import("./QuestionCard"),
    OptionRow: () => import("./OptionRow"),
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    questionTestScore: {
      type: Object,
      default: null,
    },
    is_selected: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    is_running: {
      type: Boolean,
      default: false,
    },
    can_answer: {
      type: Boolean,
      default: false,
    },
    is_finished: {
      type: Boolean,
      default: false,
    },
    evaluation_id: {
      type: Number,
    },
    save_attempt: {
      type: Number,
      default: 0,
    },
    instrumentScore: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      question: this.Question,
      temp_options: [],
      selected: [],
      multiple_choice_question_answer: {
        id: generateUniqueId(),
        question: this.Question.id,
        student: this.$store.getters.getUser.id,
        selected: [],
      },
      saving: false,
      is_saving: 0,
      answer_score: null,
      how_many_selected_limit: false,
      options_total_score: 0,
      question_score_error: false,
      option_row_key: 0,
      saved: false,
    };
  },
  computed: {
    ...mapGetters({
      questionTypes: names.QUESTION_TYPES,
      allStoredOptions: names.MULTIPLE_CHOICE_QUESTION_OPTIONS,
      selectAnswers: names.SELECT_ANSWERS,
      automatedScoreTipes: names.AUTOMATED_SCORE_TYPES,
      user: "getUser",
    }),
    storedOptions() {
      return this.allStoredOptions
        .filter((x) => x.question == this.question.id)
        .sort(function (a, b) {
          return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
        });
    },
    optionsList: {
      get() {
        let list = this.storedOptions;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (
              !isNaN(element.id) &&
              this.allStoredOptions.findIndex((x) => x.id == element.id) != -1
            ) {
              let payload = {
                option_id: element.id,
                item: {
                  order: element.order,
                },
              };
              this.$store.dispatch(
                names.PATCH_MULTIPLE_CHOICE_QUESTION_OPTION,
                payload
              );
            }
          }
          order += 1;
        });
      },
    },
    automatedScoreValue() {
      if (!this.automatedScoreTipes || !this.question.automated_score_type)
        return "";
      return this.automatedScoreTipes.find(
        (x) => x.id == this.question.automated_score_type
      ).value;
    },
    storedAndTempOptions() {
      return [...this.storedOptions, ...this.temp_options];
    },
  },
  methods: {
    slotSelectedQuestion(question_id) {
      this.$emit("selected", question_id);
    },
    slotQuestionCopied(question) {
      this.$emit("questionCopied", question);
    },
    slotEmptyQuestion(question_id) {
      if (this.multiple_choice_question_answer.selected == [])
        this.$emit("empty", question_id);
    },
    debouncePatch(option_id) {
      if (this.saving) return;
      this.saving = true;
      this.is_saving += 1;
      this.saved = false;
      this.$emit("isSaving", this.is_saving);
      setTimeout(() => {
        this.save(option_id);
        this.is_saving -= 1;
        this.$emit("isSaving", this.is_saving);
      }, 500);
    },
    forcePatch(option_selected) {
      if (this.saving) return;
      this.saving = true;
      this.is_saving += 1;
      this.saved = false;
      this.$emit("isSaving", this.is_saving);
      setTimeout(() => {
        if (this.is_saving > 0) {
          setTimeout(() => {
            this.save(option_selected);
            this.is_saving -= 1;
            this.$emit("isSaving", this.is_saving);
          }, 500);
        }
      }, 500);
    },
    save(option_id) {
      if (isNaN(this.multiple_choice_question_answer.id)) {
        this.createMultipleChoiceAnswer();
      } else {
        this.patchMultipleChoiceAnswer(option_id);
      }
    },
    createMultipleChoiceAnswer() {
      this.$store
        .dispatch(
          names.POST_SELECT_ANSWER,
          this.multiple_choice_question_answer
        )
        .then((response) => {
          this.multiple_choice_question_answer = response;
          this.saving = false;
          this.$emit("createSelectAnswer");
          toast("Se ha enviado su respuesta.");
          setTimeout(() => {
            this.saved = true;
          }, 100);
        });
    },
    patchMultipleChoiceAnswer(option_id) {
      if (
        this.multiple_choice_question_answer &&
        this.question.how_many_selected == 1
      ) {
        this.multiple_choice_question_answer.selected = [];
        this.multiple_choice_question_answer.selected = [option_id];
      }
      const payload = {
        select_answer_id: this.multiple_choice_question_answer.id,
        item: {
          selected: this.multiple_choice_question_answer.selected,
        },
      };
      this.$store
        .dispatch(names.PATCH_SELECT_ANSWER, payload)
        .then((response) => {
          this.multiple_choice_question_answer = response;
          this.saving = false;
          this.$emit(
            "updateSelectAnswer",
            this.multiple_choice_question_answer
          );
          toast("Se ha actualizado su respuesta.");
          setTimeout(() => {
            this.saved = true;
          }, 100);
        });
    },
    addOption() {
      let item = {
        text: "",
        order: this.storedAndTempOptions.length + 1,
        score: 0,
        question: this.Question.id,
        comment: "",
      };
      this.$store
        .dispatch(names.POST_MULTIPLE_CHOICE_QUESTION_OPTION, item)
        .then((response) => {
          this.question.options.push(response);
          toast("Opción creada.");
          this.saving = false;
        });
    },
    slotDeleteTempOption(option_id) {
      let index = this.temp_options.findIndex((x) => x.id == option_id);
      if (index != -1) this.temp_options.splice(index, 1);
      // this.options_total_score-=
      toast("Opción eliminada.");
    },
    slotDeleteOption(option) {
      this.options_total_score -= option.score;
    },
    fetchSimpleSelectQuestion() {
      this.$store.dispatch(
        names.FETCH_MULTIPLE_CHOICE_QUESTION,
        this.Question.id
      );
    },
    patchSimpleSelectQuestion(item) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const payload = {
          question_id: this.question.id,
          item: item,
        };
        if (!this.how_many_selected_limit) {
          this.$store
            .dispatch(names.PATCH_MULTIPLE_CHOICE_QUESTION, payload)
            .then((response) => {
              this.question = response;
              toast("Pregunta actualizada.");
            });
        }
      }, 150);
    },
    howManySelectedLimit() {
      if (this.question.how_many_selected > this.question.options.length) {
        this.how_many_selected_limit = true;
        setTimeout(() => {
          this.question.how_many_selected = this.question.options.length;
          this.how_many_selected_limit = false;
        }, 3000);
      }
    },
    slotQuestionPatched(item) {
      setTimeout(() => {
        this.$emit("questionPatched", item.score);
      }, 500);
    },
    selectQuestionScore() {
      this.$emit("selectQuestionScore", this.question.score);
    },
    slotOptionScoreChanged(item) {
      this.options_total_score += parseInt(item);
    },
    slotOptionSingleScoreChanged(item) {
      this.options_total_score = 0;
      setTimeout(() => {
        this.options_total_score += item;
      }, 500);
    },
    slotGetOptionScore(option_score) {
      this.options_total_score = 0;
      setTimeout(() => {
        this.options_total_score += option_score;
      }, 300);
    },
    slotDeletedQuestion(question) {
      this.$emit("deletedQuestion", question);
    },
    slotQuestionScoreUpdated(question_score) {
      this.$emit("questionScoreUpdated", question_score);
    },
    getMaxOptionScores() {
      //TODO: Revisar cómo sería en cada caso de método de cálculo de puntaje.
      let list = [];
      if (this.optionsList && this.question.how_many_selected > 1)
        this.optionsList.forEach((option) => {
          list.push(option.score);
        });
      let result = list
        .sort((x, y) => y - x)
        .slice(0, this.question.how_many_selected);
      result = result.reduce((partialSum, a) => partialSum + a, 0);
      return result;
    },
  },
  mounted() {
    this.getMaxOptionScores();
    // setTimeout(() => {
    // }, 500);
    this.selectQuestionScore();
    if (this.user.is_student) {
      let payload = {
        question_id: this.question.id,
        user_id: this.user.id,
      };
      this.$store
        .dispatch(names.FETCH_SELECT_ANSWERS, payload)
        .then((response) => {
          if (response) {
            const answer = response.find((x) => x.student == this.user.id);
            if (answer) {
              if (
                !this.is_running &&
                this.user.is_student &&
                this.is_finished &&
                this.instrumentScore &&
                this.instrumentScore.score_and_feedback &&
                this.instrumentScore.score_and_feedback.publish
              ) {
                if (answer.score_and_feedback.score)
                  this.answer_score = answer.score_and_feedback.score;
                else this.answer_score = answer.automated_score;
              }
              this.multiple_choice_question_answer = answer;
              this.$emit("createSelectAnswer");
            }
          }
        });
    }
  },
  created() {
    this.fetchSimpleSelectQuestion();
    this.$store.dispatch(
      names.FETCH_MULTIPLE_CHOICE_QUESTION_OPTIONS,
      this.question.id
    );
  },
  watch: {
    "question.how_many_selected"() {
      this.option_row_key += 1;
      if (this.question.how_many_selected >= 1) {
        if (this.question.how_many_selected == 1) {
          this.patchSimpleSelectQuestion({
            automated_score_type: 1,
            how_many_selected: this.question.how_many_selected,
          });
        } else {
          this.patchSimpleSelectQuestion({
            how_many_selected: this.question.how_many_selected,
          });
        }
      }
    },
    "question.automated_score_type"() {
      if (this.question.how_many_selected >= 1) {
        this.patchSimpleSelectQuestion({
          automated_score_type: this.question.automated_score_type,
        });
      }
    },
    save_attempt() {
      if (this.save_attempt > 0) {
        let payload = {
          question_id: this.multiple_choice_question_answer.question,
          answer:
            this.multiple_choice_question_answer.selected.length == 0
              ? ""
              : "selected",
        };
        this.$emit("emptyAnswer", payload);
      }
    },
    options_total_score() {
      this.options_total_score = this.getMaxOptionScores();
      if (
        this.options_total_score != this.Question.score &&
        this.question.how_many_selected > 1
      ) {
        this.question_score_error = true;
      } else {
        this.question_score_error = false;
      }
    },
  },
};
</script>
<style scoped>
.content-div {
  margin: 0px 0.5rem;
}
.check-icon {
  border-radius: 4px !important;
  overflow: hidden !important;
}
.check-icon,
.square-icon {
  margin-top: 0.5em;
  opacity: 0.6;
}
.option-text {
  margin-top: 0.2em;
  margin-left: 0.9em;
  text-align: left;
}
.margin-bottom-adjust >>> p {
  margin-bottom: 0.4em !important;
  margin-left: 0.3em;
  padding-bottom: 0 !important;
}
.check-question {
  margin-top: 0.7em;
}
.how-many-selected-input {
  width: 45%;
}
.handler-icon {
  margin-top: 0.8em;
  margin-right: 1%;
}
.grabbable-cursor {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable-cursor:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.options-div:first-of-type {
  margin-top: 0.7em;
}
.draggable-section {
  padding: 2px;
  padding-top: 8px;
  border-radius: 5px;
}
.draggable-section:hover {
  background: rgb(249, 249, 249);
}
.form-group >>> .how-many-options-label {
  padding-top: 0.9em;
}
.option-text {
  width: 100%;
}
.empty-options,
.options-remaining {
  background: red !important;
}
.options_score_error {
  color: #dc3545;
}
.options_score_error_border {
  border: 1px solid #dc3545 !important;
}
.save-answer-btn {
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  transform: translateY(125%);
}
.saving-answer-btn {
  width: 8.4em;
}
.btn-save-container {
  display: flex;
  margin: auto;
}
.dot-flashing {
  position: relative;
  margin-top: 0.65em;
  left: 11px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #6c757d;
  color: #6c757d;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -9px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #6c757d;
  color: #6c757d;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 9px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #6c757d;
  color: #6c757d;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}
.text-alert {
  color: red;
  opacity: 1;
}
.blinking {
  animation: opacity 0.2s ease-in-out;
  opacity: 1;
}

.automated-score-type-info {
  text-align: justify;
  /* word-break: break-all; */
  hyphens: auto;
}
.answer {
  background: rgb(242, 242, 242);
}
.save-btn-container {
  display: flex;
}

.saved-answer-alert {
  margin-left: auto;
  margin-right: 0;
  transform: translateY(125%);
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #6c757d;
  }
  50%,
  100% {
    background-color: #ffffff;
  }
}
</style>